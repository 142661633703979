html {
  font-size: 12px !important;
}

:root {
  --font-family: "Ubuntu", sans-serif;
}

body {
  font-family: var(--font-family);
}

@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import "app/layout";
@import "themes/aura-light-blue/theme.css";
@import "custom.scss";
