.layout-sidebar {
  position: sticky;
  left: 0;
  top: 6rem;
  height: calc(100vh - 9rem);
  user-select: none;
  transition: transform .4s cubic-bezier(.05, .74, .2, .99), opacity .3s;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 0;
  flex: 0 0 250px;
  margin-right: 4rem;
  overflow: auto;

  .logo {
    display: flex;
    justify-content: center;
  }

  nav {
    padding: 0 1rem 0 0;
    margin: 0;
    flex-grow: 1;
  }

  .layout-menu {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      margin-bottom: .25rem;

      > button,
      > a {
        display: flex;
        width: 100%;
        align-items: center;
        padding: .5rem 0;
        color: var(--surface-900);
        font-weight: 600;
        transition: all .2s;
        position: relative;
        border-radius: var(--border-radius);

        .menu-icon {
          width: 2rem;
          height: 2rem;
          border-radius: var(--border-radius);
          margin-right: .5rem;
          border: 1px solid var(--surface-border);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: all .2s;
          position: relative;
          background-color: transparent;

          i {
            color: var(--surface-700);
            transition: all .2s;
          }
        }

        .menu-toggle-icon {
          color: var(--surface-700);
          margin-left: auto;
        }

        &:hover {
          .menu-icon {
            background-color: var(--surface-card);

            i {
              color: var(--primary-color-default);
            }
          }

          .menu-toggle-icon {
            color: var(--surface-900);
          }
        }

        &.router-link-active {
          color: var(--primary-color-default);

          > .menu-icon {
            i {
              color: var(--primary-color-default);
            }
          }
        }

        @include focus-visible(inset);
      }

      > div {
        ol {
          margin: 0 0 0 1rem;
          padding: .25rem 0;
          list-style: none;

          li {
            a {
              color: var(--surface-700);
              border-left: 1px solid var(--surface-border);
              transition: all .2s;
              font-weight: 450;
              display: flex;
              align-items: center;
              padding: .5rem .5rem .5rem 1rem;
              color: var(--surface-700);
              transition: all .2s;
              position: relative;

              &:focus-visible {
                outline: 0 none;
                box-shadow: inset var(--focus-ring);
              }

              &:hover {
                color: var(--surface-900);
                border-left-color: var(--surface-500);
              }

              &.router-link-active {
                color: var(--primary-color-default);
                border-left-color: var(--primary-color-default);
              }
            }

            ol {
              margin: 0;
              padding: 0;
            }

            &:has(.menu-child-category) {
              margin-top: 1rem;
            }

            &:has(.menu-child-category):first-child {
              margin-top: 0rem;
            }
          }
        }
      }
    }

    .p-tag {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      .p-tag-value {
        line-height: 1;
      }
    }

    .menu-child-category {
      display: flex;
      padding: .5rem .5rem .5rem 0;
      font-size: .875rem;
      font-weight: 600;
      letter-spacing: 1px;
      color: var(--surface-900);
      margin-bottom: .25rem;
    }
  }
}
