.DocSearch-Button {
  border-radius: var(--border-radius);
  border: 1px solid var(--surface-border);
  height: 2rem;
  background-color: var(--surface-card);
  margin: 0;
  transition: all .3s;
  padding: 0 .5rem;
  @include focus-visible();

  &:hover {
    border-color: var(--primary-color);
  }

  &:hover {
    border-color: var(--primary-color);
    box-shadow: none;
  }

  .DocSearch-Search-Icon {
    width: 1rem;
    height: 1rem;
  }

  .DocSearch-Button-Keys {
    overflow: hidden;
    min-width: auto;
    height: auto;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 0;
    gap: 2px;

    .DocSearch-Button-Key {
      background: transparent;
      display: flex;
      padding: 0;
      margin: 0;
      top: 0;
      border-radius: 0;
      height: auto;
      width: auto;
      font-family: var(--font-family);

      &:first-child {
        font-size: 0.75rem;
        line-height: normal;
      }

      &:last-child {
        justify-content: start;
        align-items: center;
        font-size: 0.75rem;
        position: relative;

        &::before {
          content: "\e90d";
          display: flex;
          color: var(--text-color);
          font-family: "primeicons";
          font-size: .4rem;
          opacity: .7;
          margin-right: 2px;
          height: 13.5px;
          align-items: center;
          font-weight: 700;

        }
      }
    }
  }
}

.DocSearch-Container {
  z-index: 1101;
}

.DocSearch-Modal {
  border: 1px solid var(--surface-border);
  box-shadow: none;
}

.DocSearch-Footer {
  box-shadow: none;
  border-top: 1px solid var(--surface-border);
  background-color: var(--surface-overlay);
}

.DocSearch-Form {
  background: var(--surface-card);
  box-shadow: none;
  border: 1px solid var(--surface-border);
  border-radius: var(--border-radius);
  transition: border-color .3s;

  &:hover {
    border-color: var(--primary-color);
  }

  .DocSearch-MagnifierLabel, .DocSearch-Reset {
    color: var(--text-color);
  }
}

.DocSearch-Hit {
  border-bottom: 1px solid var(--surface-border);
  padding-bottom: 0;
  margin-bottom: .25rem;
}

.DocSearch-Hit-source {
  color: var(--primary-color);
}

.DocSearch-Logo .cls-1, .DocSearch-Logo .cls-2 {
  fill: var(--primary-color);
}

.DocSearch-Prefill {
  color: var(--primary-color);
}

.DocSearch-Button-Placeholder {
  text-align: center;
  display: inline-block;
  font-size: .875rem;
}

:root {
  --docsearch-searchbox-focus-background: var(--surface-card);
  --docsearch-text-color: var(--text-color);
  --docsearch-muted-color: var(--text-color);
  --docsearch-searchbox-background: var(--surface-card);
  --docsearch-text-color: var(--text-color);
  --docsearch-modal-background: var(--surface-overlay);
  --docsearch-key-gradient: var(--surface-ground);
  --docsearch-key-shadow: none;
  --docsearch-container-background: var(--maskbg);
  --docsearch-hit-background: var(--surface-overlay);
  --docsearch-hit-shadow: none;
  --docsearch-spacing: 1rem;
  --docsearch-hit-color: var(--text-color);
  --docsearch-highlight-color: var(--primary-color);
  --docsearch-hit-active-color: var(--primary-color-text);
}

