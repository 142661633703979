.layout-footer {
  padding: 2rem 4rem;
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: 500;
  color: var(--text-800);
  border-top: 1px solid var(--surface-border);

  a {
    color: var(--primary-color);
    font-weight: 700;
    transition: all .3s;
    border-radius: var(--border-radius);

    @include focus-visible();

    &:hover {
      text-decoration: underline;
    }

  }
}
