.layout-topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - var(--scrollbar-width, 0px));
  z-index: 1100;
  transition: background-color .5s, border-color .5s;
  border-bottom: 1px solid transparent;

  &.layout-topbar-sticky {
    border-bottom: 1px solid var(--surface-border);
    background-color: var(--topbar-sticky-bg);
    backdrop-filter: blur(8px);
  }
}

.layout-topbar-inner {
  height: 4rem;
  padding: 0 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .layout-topbar-logo-container {
    width: 250px;
    margin-right: 4rem;
  }

  .layout-topbar-logo,
  .layout-topbar-icon {
    border-radius: var(--border-radius);
    @include focus-visible();

    svg {
      width: 120px;
    }
  }

  .layout-topbar-logo {
    display: inline-flex;

    svg {
      width: 120px;
    }
  }

  .layout-topbar-icon {
    display: none;

    svg {
      width: 25px;
    }
  }

  .menu-button {
    display: none;
  }
}
