html {
  font-size: 14px;
}

body {
  margin: 0px;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-ground);
  font-weight: normal;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.layout-wrapper {
  background-color: var(--surface-ground);
}

a {
  text-decoration: none;
}

::selection {
  background-color: var(--highlight-bg);
  color: var(--highlight-text-color);
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: var(--surface-900);

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: .875rem;
}

p {
  line-height: 1.75;
  margin: 0 0 1rem 0;
}

input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.p-toast.p-toast-top-right,
.p-toast.p-toast-top-left {
  top: 7rem;
}

.px-link {
  font-size: 1rem;
  border-radius: var(--border-radius);
  text-align: left;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: none;

  @include focus-visible();
}

.px-link:disabled {
  cursor: default;
}

button {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings);
}
